// Variables
$small: 32px;
$medium: 96px;
$large: 128px;

// Functions
@mixin setSquareSize($size) {
  width: $size;
  height: $size;
}
@mixin setSizeModificators() {
  &--small {
    @include setSquareSize($small);
  }
  &--medium {
    @include setSquareSize($medium);
  }
  &--large {
    @include setSquareSize($large);
  }

// Styles
}
.shape {
  display: flex;
  align-self: center;
  &-size {
    @include setSizeModificators();
  }
  &-type {
    &--rounded {
      border-radius: $radius-medium;
    }
    &--circle {
      border-radius: $radius-circle;
    }
  }
}
@media (min-width: $screen-xs) {
  .shape {
    &-xs {
      &-size {
        @include setSizeModificators();
      }
    }
  }
}
@media (min-width: $screen-sm) {
  .shape {
    &-sm {
      &-size {
        @include setSizeModificators();
      }
    }
  }
}
@media (min-width: $screen-md) {
  .shape {
    &-md {
      &-size {
        @include setSizeModificators();
      }
    }
  }
};