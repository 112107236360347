/// @type px
$spacing-small: 4px;
/// @type px
$spacing-base: 12px;
/// @type px
$spacing-medium: 28px;
/// @type px
$spacing-large: 34px;
/// @type px
$spacing-print-large: 26px;

/// @type list of strings
$spacing-names: 'left', 'top', 'right', 'bottom';

/// @name Basic Spacing Config
/// @example
///  +withoutSpacing(false, true, left)
///  result: padding-left: 0
@mixin withoutSpacing($margin: true, $padding: true, $side: null) {
  $margin-name: 'margin';
  $padding-name: 'padding';
  @if $side != null {
    $margin-name: 'margin-#{$side}';
    $padding-name: 'padding-#{$side}';
  }
  @if $margin == true {
    #{$margin-name}: 0;
  }
  @if $padding == true {
    #{$padding-name}: 0;
  }
}
/// @name No spacing classes generator
@mixin generateNoSpacingClasses($spacingType) {
  @each $name in $spacing-names {
    $spacing-name: '&-#{$name}--none';
    // class name &-top--none
    #{$spacing-name} {      // set paddings or margins
      @if $spacingType == 'padding' {
        @include withoutSpacing(false, true, $name);
      }
      @if $spacingType == 'margin' {
        @include withoutSpacing(true, false, $name);
      }
    }/// @name Modifier spacing classes generator
  }
}
@mixin generateSpacingModifierClasses($name, $direction:false) {
  @if $direction == false {
    &--base {
      #{$name}: $spacing-base !important;
    }
    &--small {
      #{$name}: $spacing-small !important;
    }
    &--medium {
      #{$name}: $spacing-medium !important;
    }
    &--large {
      #{$name}: $spacing-large !important;
    }
    &--none {
      #{$name}: 0 !important;
    }
  }@else {
    &--base {
      #{$name}-#{$direction}: $spacing-base !important;
    }
    &--small {
      #{$name}-#{$direction}: $spacing-small !important;
    }
    &--medium {
      #{$name}-#{$direction}: $spacing-medium !important;
    }
    &--large {
      #{$name}-#{$direction}: $spacing-large !important;
    }
    &--none {
      #{$name}-#{$direction}: 0 !important;
    }
  }
}// @name Spacing Classes generator
@mixin generateSpacingClasses($name) {  // Generate four side spacing class
  @include generateSpacingModifierClasses($name, false);
  &-left {
    @include generateSpacingModifierClasses($name, 'left');
  }
  &-top {
    @include generateSpacingModifierClasses($name, 'top');
  }
  &-right {
    @include generateSpacingModifierClasses($name, 'right');
  }
  &-bottom {
    @include generateSpacingModifierClasses($name, 'bottom');
  }
/// @desc use it like margin-[direction]--[modifier*]
/// @example margin-top--small
/// @example margin-top
}
.margin {
  @include generateNoSpacingClasses('margin');
  @include generateSpacingClasses('margin');
}
/// @desc use it like padding-[direction]--[modifier*]
//        * modifier is optional
/// @example padding-top--small
.padding {
  @include generateNoSpacingClasses('padding');
  @include generateSpacingClasses('padding');
}