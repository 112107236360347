/// @type hex
$color-black: #111111;
/// @type hex
$color-graphite: #657486;
/// @type hex
$color-graphite--light: #d2d9e0;
/// @type hex
$color-graphite--dark: #242a33;
/// @type hex
$color-red: #c6051f;
/// @type hex
$color-blue: #429bf4;
/// @type hex
$color-grey: #999999;
/// @type hex
$color-grey--light: #f9f9f9;
/// @type hex
$color-grey--dark: #444444;
/// @type hex
$color-white: #ffffff;

/// $type string
$color-transparent: transparent;
/// $type hex
$color-twitter: #00AFFF;
/// $type hex
$color-facebook: #1D61E0;
/// $type hex
$color-linkedin: #326EBE;
/// $type hex
$color-instagram: #FF0063;
/// $type hex
$color-snapchat: #FFE300;

/// @name Color Set Mixins
@mixin colorSets($colorType) {
  $settings: unquote($colorType);
  &-grey {
    #{$colorType}: $color-grey !important;
  }
  &-graphite {
    #{$colorType}: $color-graphite !important;
    &--dark {
      #{$colorType}: $color-graphite--dark !important;
    }
  }
  &-red {
    #{$colorType}: $color-red !important;
  }
  &-blue {
    #{$colorType}: $color-blue !important;
  }
  &-white {
    #{$colorType}: white !important;
  }
  &-vendor {
    &--facebook {
      #{$colorType}: $color-facebook;
    }
    &--twitter {
      #{$colorType}: $color-twitter;
    }
    &--linkedin {
      #{$colorType}: $color-linkedin;
    }
    &--instagram {
      #{$colorType}: $color-instagram;
    }
    &--snapchat {
      #{$colorType}: $color-snapchat;
    }
/// @name Color classes
  }
}
.color {
  @include colorSets('color');
}
/// @name Background color classes
.bg-color {
  @include colorSets('background');
}