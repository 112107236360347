.work-exp-item {
    margin: $spacing-base 0 $spacing-large 0;
  
    &__timespan {
      display: block;
      font-size: $font-size-text--base;
      margin-bottom: -$spacing-base;
      color: $color-graphite;
    }
  }
  @media print {
    .work-exp-item {
      margin: $spacing-small 0 $spacing-print-large 0;
    }
  };