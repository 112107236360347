$icon-small: 20px;
$icon-medium: 30px;
$icon-large: 40px;

@mixin setCircleIcon($size) {
  font-size: $size * 0.66;
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $radius-circle;
  margin: 0 $spacing-base;
}
.icon {
  @include setCircleIcon($icon-small);
  color: white;
  &-color {
    &--blue {
      background-color: $color-blue;
    }
  }
}