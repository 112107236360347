.text {
  &--center {
    text-align: center;
    justify-content: center;
  }
  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }
}
@media print {
  .text {
    &-print {
      &--left {
        text-align: left;
      }
    }
  }
}
@media (min-width: $screen-md) {
  .text {
    &-md {
      &--right {
        text-align: right;
        justify-content: flex-end;
      }
      &--left {
        text-align: left;
        justify-content: flex-start;
      }
      &--center {
        text-align: center;
        justify-content: center;
      }
    }
  }
}
@media (min-width: $screen-sm) {
  .text {
    &-sm {
      &--center {
        text-align: center;
        justify-content: center;
      }
    }
  }
}
@media (max-width: $screen-xs) {
  .text {
    &-xs {
      &--center {
        text-align: center;
        justify-content: center;
      }
    }
  }
};