.flex {
  display: flex !important;
  flex-direction: column;

  &--row {
    flex-direction: row;
  }
}
@media (min-width: $screen-md) {
  .flex {
    &-md {
      &--column {
        flex-direction: column;
      }
    }
    &-md {
      &--row {
        flex-direction: row;
      }
    }
  }
}
@media (min-width: $screen-xs) {
  .flex {
    &-xs {
      &--column {
        flex-direction: column;
      }
    }
    &-xs {
      &--row {
        flex-direction: row;
      }
    }
  }
};