.circle-sep {
    font-size: $font-size-text--tiny*0.5;
    vertical-align: middle;
    color: $color-grey;
    margin: 0 $spacing-base;
    display: inline-block;
  }
  footer .circle-sep {
    display: none;
  }
  
  @media screen and (min-width: $screen-md) {
    footer .circle-sep {
      display: inline-block;
    }
  };