.header {
    @include withoutSpacing();
    @include setFontSet($font-size-header--base, $font-weight--normal);
  
    &--em {
      font-style: normal;
    }
    a {
      color: inherit;
    }
    i {
      margin-right: $spacing-base*0.5;
    }
    &--main {
      @include setFontSet($font-size-header--large - 6, $font-weight--light, capitalize);
      margin: $spacing-medium 0;
    }
    &--section {
      @include setFontSet($font-size-header-xs--base,  $font-weight--light, capitalize);
      margin: $spacing-base 0;
    }
    &--small {
      @include setFontSet($font-size-header-xs--small,  $font-weight--normal, capitalize);
      margin-top: $spacing-base;
    }
    &--tiny {
      @include setFontSet($font-size-header--tiny,  $font-weight--bold, uppercase);
      margin: $spacing-base 0 $spacing-small 0;
      color: $color-graphite;
    }
    &--special {
      @include setFontSet($font-size-header--tiny,  $font-weight--bold, uppercase);
      margin: $spacing-base 0 $spacing-small 0;
      color: $color-red;
    }
  }
  @media print {
    .header {
      &--tiny {
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: $screen-sm) {
    .header {
      &--main {
        @include setFontSet($font-size-header--large, $font-weight--light, capitalize);
        color: $color-black;
      }
      &--section {
        @include setFontSet($font-size-header--base,  $font-weight--light, capitalize);
        margin: $spacing-base 0;
      }
      &--small {
        @include setFontSet($font-size-header--small,  $font-weight--normal, capitalize);
        margin-top: $spacing-base;
      }
    }
  };