.list {

    margin-bottom: $spacing-medium;
    &--no-bullets {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &__item {
      margin-bottom: $spacing-small;
    }
  }
  @media (min-width: $screen-sm) {
    .list {
      &-sm {
        &--inline {
          &__item {
            display: inline-block;
            margin: $spacing-small $spacing-medium $spacing-base $spacing-medium;
          }
        }
      }
    }
  }
  @media (min-width: $screen-md) {
    .list {
      &-md {
        &--block {
          &__item {
            display: list-item;
            text-align: left;
          }
        }
      }
    }
  };